import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import BlueHeader from '../../components/common/BlueHeader'
import RenderBlock from '../../components/blocks/RenderBlock'
import Container from '../../components/common/Container'
import FAQListing from '../../components/pages/FAQListing'
import Offers from '../../components/common/Offers'

const FAQ = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='en'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='en'
    />

    <BlueHeader title={data.defaultPage.title} />

    <main>
      <Container width={830}>
        <FAQListing
          locale='en'
          index={data.siteSearchIndex.index}
          original={data.allFaqPage.edges}
        />
      </Container>
    </main>

    <RenderBlock blocks={data.defaultPage.blocks} />

    <Footer
      extraRules='*Royale tissue products are manufactured by Irving Consumer Products Limited, an affiliate of J.D. Irving, Limited. J.D. Irving, Limited is recognized for responsible forest management and contributions to ecosystem research, habitat conservation and reforestation.'
    />
    <Offers />
  </Layout>
)

export const query = graphql`
query faqPage {
  defaultPage(guid: { eq: "c1ba4863-8f29-4802-bd3d-0f74c7244288" }, locale:{eq:"en"}) {
    title
    title_html
    subtitle
    description
    blocks {
      __typename
      ...productCategoriesBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
    }
  }
  allFaqPage(filter:{locale:{eq:"en"}}) {
    edges {
      node {
        guid
        question
        answer
        category
      }
    }
  }
  siteSearchIndex {
    index
  }
}
`

export default FAQ
